import {
   createAdminEncounterSchema,
   dateField,
   intExtendedField,
   RuleBasedBoundsResponseDto,
   TimetapLocationType,
   uuidSchema,
   WelkinEncounterTemplateNames,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const scheduling = c.router(
   {
      getClinicianLocations: {
         method: 'GET',
         path: '/scheduling/clinician-locations',
         responses: {
            200: c.type<
               {
                  locationId: number;
                  name: string;
                  type: TimetapLocationType;
               }[]
            >(),
         },
      },

      getClinicianServices: {
         method: 'GET',
         path: '/scheduling/clinician-services',
         query: z.object({
            // needed to extract the state and check if clinician provides services there
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<
               {
                  serviceId: number;
                  name: string;
                  welkinEncounterTemplate: WelkinEncounterTemplateNames;
               }[]
            >(),
         },
      },

      getRuleBasedBounds: {
         method: 'GET',
         path: '/scheduling/rule-based-bounds',
         query: z.object({
            patientId: z.string().uuid(),
            serviceId: intExtendedField,
         }),
         responses: {
            200: c.type<RuleBasedBoundsResponseDto>(),
         },
      },

      getClinicianMonthlyAvailability: {
         method: 'GET',
         path: '/scheduling/clinician-monthly-availability',
         query: z.object({
            locationId: intExtendedField,
            serviceId: intExtendedField,
            year: intExtendedField,
            month: intExtendedField.refine(
               (val) => val >= 1 && val <= 12,
               'Month must be between 1 and 12',
            ),
         }),
         responses: {
            // returns array of available dates
            200: c.type<string[]>(),
         },
      },

      getClinicianAvailabilityForDate: {
         method: 'GET',
         path: '/scheduling/clinician-day-availability',
         query: z.object({
            date: dateField,
            locationId: intExtendedField,
            serviceId: intExtendedField,
         }),
         responses: {
            200: c.type<
               {
                  // date time ISO string in UTC
                  start: string;
                  end: string;
               }[]
            >(),
         },
      },

      getEncounterBankForEncounter: {
         method: 'GET',
         path: '/scheduling/encounter-bank',
         query: z.object({
            encounterTemplate: z.nativeEnum(WelkinEncounterTemplateNames),
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<{
               hasValidInsurance: boolean;

               availableToSchedule: number | 'infinite';
               // null in purchased means n/a - insurance patients only
               purchasedCount: number | null;
            }>(),
         },
      },

      scheduleEncounter: {
         method: 'POST',
         path: '/scheduling/encounter',
         body: createAdminEncounterSchema,
         responses: {
            200: c.type<{
               timetapId: number;
               welkinEncounterId: string;
            }>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
