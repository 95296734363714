import { initContract } from '@ts-rest/core';
import {
   ClinicianResponseDto,
   ClinicianWithExtraDataResponse,
   createClinicianSchema,
   offsetPaginationSchema,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const clinicians = c.router(
   {
      createClinicians: {
         method: 'POST',
         path: '/clinicians',
         body: createClinicianSchema,
         responses: {
            200: c.type<Partial<ClinicianResponseDto>>(),
         },
      },

      updateClinician: {
         method: 'PATCH',
         path: '/clinicians/:id',
         body: createClinicianSchema.partial(),
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<Partial<ClinicianResponseDto>>(),
         },
      },

      getClinician: {
         method: 'GET',
         path: '/clinicians/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<ClinicianWithExtraDataResponse>(),
         },
      },

      getClinicians: {
         method: 'GET',
         path: '/clinicians',
         query: offsetPaginationSchema,
         responses: {
            200: c.type<ClinicianWithExtraDataResponse[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
